<template>
  <div class="content-container">
    <span class="title-login">Autentificare</span>
    <form @submit.prevent="submitForm" autocomplete="off">
      <div class="form-group has-feedback">
        <label for="e_field" class="custom-label">Utilizator</label>
        <input
          v-model.trim="username"
          type="text"
          class="form-control custom-input"
          value=""
          placeholder="Introduceţi numele de utilizator"
          title="Te rog să completezi acest câmp."
          autocapitalize="false"
          autocorrect="false"
          autocomplete="false"
          required=""
        />
      </div>
      <div class="form-group  has-feedback">
        <label for="p_field" class="custom-label">Parola</label>

        <input
          v-model.trim="password"
          ref="passInput"
          :type="passwordHidden ? 'password' : 'text'"
          class="form-control custom-input custom-password"
          placeholder="Introduceți parola"
          title="Te rog să completezi acest câmp."
          autocapitalize="false"
          autocorrect="false"
          autocomplete="false"
          required=""
          readonly=""
          onfocus="this.removeAttribute(&#39;readonly&#39;);"
        />
        <span
          @click="passwordHidden = !passwordHidden"
          :class="{
            'fa fa-fw fa-eye field-icon toggle-show-password': passwordHidden,
            'fa fa-fw field-icon toggle-show-password fa-eye-slash': !passwordHidden,
          }"
        ></span>
      </div>
      <div class="clearfix"></div>
      <div class="row">
        <div class="col-md-6 col-xs-6">
          <router-link to="/register/fizic" class="white-font"
            >Înregistrează-te</router-link
          >
        </div>
        <div class="col-md-6 col-xs-6 text-right">
          <router-link to="/forgot/password" class="white-font"
            >Ai uitat parola?</router-link
          >
        </div>
      </div>
      <div class="form-group">
        <div class="text-center">
          <input
            type="submit"
            id="submitLogin"
            class="submit-btn white-font"
            value="Autentificare"
            autocomplete="false"
            autocapitalize="false"
            autocorrect="false"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { LOGIN } from "../api.js";
import JWT from "vuejs-jwt";

const usernameValidate = /^[a-zA-Z0-9_]{5,}[a-zA-Z]+[0-9]*$/;

export default {
  title: "ConectX - Autorizare",
  components: {
    JWT,
  },
  data: () => ({
    passwordHidden: true,
    username: "",
    password: "",
    apiLoading: false,
  }),
  watch: {
    passwordHidden() {
      window.requestAnimationFrame(() => {
        this.$refs.passInput.focus();
      });
    },
  },
  methods: {
    submitForm() {
      if (this.apiLoading) return;
      if (this.username.trim() && this.password.trim()) {
        const error = (msg) => {
          this.$toastr.e(msg || "Autorizarea a eșuat");
          this.apiLoading = false;
        };

        this.apiLoading = true;
        LOGIN(String(this.username).trim(), String(this.password).trim())
          .then((res) => {
            if (this.isString(res?.data?.token?.access_token)) {
              const login = this.login(res.data.token.access_token);
              if (login !== true) {
                if (login == "disabled") {
                  this.password = "";
                  this.passwordHidden = true;
                  this.$refs["passInput"]?.focus();
                }
              }
            } else {
              if (String(res?.statusCode)[0] === "5") {
                this.$toastr.e("Internal server error", "Autorizare eșuată");
              } else if (res?.statusCode == 404 && res.response == 'user_not_found') {
                this.$toastr.e("Utilizator inexistent.", "Autorizare eșuată");
              } else if (res?.statusCode == 401) {
                this.$toastr.e(
                  "Combinația utilizator / parolă este greșită.",
                  "Autorizare eșuată"
                );
              } else {
                this.$toastr.e("Autorizarea a eșuat");
              }
            }
            this.apiLoading = false;
          })
          .catch(error);
      } else {
        this.$toastr.e(
          "Datele au fost introduse incorect",
          "Autorizare eșuată"
        );
      }
    },
  },
};
</script>
